<template>
  <main class="mx-auto scroll-smooth">
    <HomeBanner />
    <HomeExpertise />
    <LazyHomeInnovation v-if="showAllComponents" />
    <LazyHomeClients v-if="showAllComponents" />
    <LazyHomeIdeas v-if="showAllComponents" />
  </main>
</template>

<script setup>
import { useGlobalStore } from '~/store/global'

const global = useGlobalStore()
const mainSeo = global.mainSeo
const mainBanner = global.mainBanner

const showAllComponents = ref(false)

useJsonld({
  '@context': 'https://schema.org/',
  '@type': 'LocalBusiness',
  name: mainSeo?.attributes?.seo?.seo_title || 'Suitmedia',
  url: mainSeo?.attributes?.url || 'suitmedia.com',
  description:
    mainSeo?.attributes?.seo?.seo_description || 'Digital Agency Indonesia',
  image:
    mainSeo?.attributes?.seo?.image?.data?.attributes?.url ||
    mainBanner.attributes?.banner?.image?.data?.attributes?.url ||
    '/icon.png',
  email: 'contact(at)suitmedia.com',
  telephone: '+62 21 719 6877',
  legalName: 'PT Suitmedia Kreasi Indonesia',
  location: 'Jakarta, Bandung, Yogyakarta, Singapore',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Jakarta, Indonesia',
    postalCode: '12510',
    streetAddress: 'Jl. Pejaten Barat II No. 3A'
  }
})

useSeoMeta({
  title: mainSeo?.attributes?.seo?.seo_title || 'Suitmedia',
  ogTitle: mainSeo?.attributes?.seo?.seo_title || 'Suitmedia',
  description:
    mainSeo?.attributes?.seo?.seo_description || 'Digital Agency Indonesia',
  ogDescription:
    mainSeo?.attributes?.seo?.seo_description || 'Digital Agency Indonesia',
  ogImage:
    mainSeo?.attributes?.seo?.image?.data?.attributes?.url ||
    mainBanner.attributes?.banner?.image?.data?.attributes?.url ||
    '/icon.png',
  ogType: mainSeo?.attributes?.seo?.open_graph_type,
  twitterTitle: mainSeo?.attributes?.seo?.seo_title || 'Suitmedia',
  twitterDescription:
    mainSeo?.attributes?.seo?.seo_description || 'Digital Agency Indonesia',
  twitterImage:
    mainSeo?.attributes?.seo?.image?.data?.attributes?.url ||
    mainBanner.attributes?.banner?.image?.data?.attributes?.url ||
    '/icon.png'
})

const expires = new Date()
expires.setTime(expires.getTime() + 1000 * 60 * 60 * 24) // 1 Day

global.darkTheme = useCookie('theme', {
  default: () => false,
  expires
})

// lazy component scroll behaviour
onMounted(() => {
  document.addEventListener('scroll', () => {
    showAllComponents.value = true
  })

  document.addEventListener('click', () => {
    showAllComponents.value = true
  })

  document.addEventListener('mousemove', () => {
    showAllComponents.value = true
  })

  document.addEventListener('touchmove', () => {
    showAllComponents.value = true
  })
})

// lazy component scroll behaviour
</script>
